import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { Box } from '@mui/material';
import imageSchool from '/images/cct-3.png';
import krtSudentCapital from '/images/cct-2.png';
import students_black from '/images/student-black.png';
import students_red from '/images/student-red.png';
import students_blue from '/images/student-blue.png';
import logo1 from '/images/home12.png';
import logo2 from '/images/home8.png';
import logo3 from '/images/home10.png';
import logo4 from '/images/home11.png';
import logo5 from '/images/home13.png';
import FilterKRT from './FilterKRT'
import CheckbooxButton from '../../../components/CheckbooxButton'

const LIST_PROVICNE = [
  "กาญจนบุรี",
  "ขอนแก่น",
  "นครนายก",
  "นครราชสีมา",
  "น่าน",
  "พิษณุโลก",
  "ภูเก็ต",
  "มหาสารคาม",
  "ยะลา",
  "ระยอง",
  "ลำปาง",
  "สงขลา",
  "สุราษฎร์ธานี",
  "สุรินทร์",
  "สุโขทัย",
  "อำนาจเจริญ",
  "อุบลราชธานี",
  "เชียงใหม่",
  "แพร่",
  "แม่ฮ่องสอน"
]

const Overview = (props) => {
  console.log("props", props)
  let { data, filter, setFilter } = props;

  let total_oosc = props.data.reduce((acc, b) => acc + b.total_oosc, 0)
  let target_oosc = props.data.reduce((acc, b) => acc + b.total_target_map_oosc, 0)
  let sum_oosc = props.data.reduce((acc, b) => acc + b.province20_map_oosc, 0)
  let key_map = 'province'
  let isShow = false
  let titleTable = 'จังหวัด'
  if (props.filter.view === 'province') {
    key_map = 'city'
    isShow = true
    titleTable = 'อำเภอ'
  } else if (props.filter.view === 'city') {
    key_map = 'tumbon'
    isShow = true
    titleTable = 'ตำบล'
  }

  return (
    <div className='flex flex-col'>
      <div className='w-[full] flex justify-center'>
        <div className=' flex flex-col' >
          <div className=' bg-white flex flex-row   border-solid border-2 border-gray-200 p-2 mx-2 mb-1 rounded-lg' >
            <div className=' flex flex-col' >
              <h5 className="text-sm m-0 ml-[9px]" >ข้อมูลปี: </h5>
              <div className='flex flex-row' >
                <CheckbooxButton title='2563' checked={props.filter.year === '2563'} onClick={() => {
                  props.onUpdateFilter({ ...props.filter, year: '2563' })
                }} />
                <CheckbooxButton title='2562' checked={props.filter.year === '2562'} onClick={() => {
                  props.onUpdateFilter({ ...props.filter, year: '2562' })
                }} />
              </div>
            </div>
            < FilterKRT
              listprovince={LIST_PROVICNE}
              data={props.filter}
              onChange={(res) => {
                props.onUpdateFilter({ ...props.filter, ...res })
              }}
            />
          </div>
        </div>
      </div>

      <div className='pt-2 px-4 text-cennter content-center'>
        <div className='flex flex-col md:flex-row justify-between'>
          {/* <div className="w-full bg-white border-[1px] border-solid mx-auto rounded-xl shadow-xl  w-[220px] p-1 my-2">
                  <h2 className='text-md md:text-sm lg:text-md m-0 text-center'>เด็ก/เยาวชนที่ไม่มีข้อมูล</h2>
                  <h2 className='text-md md:text-sm lg:text-md m-0 text-center'>ในระบบการศึกษทั้งหมด</h2>
                  <h2 className='text-md lg:text-2xl text-center text-[#3440A6] m-0 font-bold'>{total_oosc.toLocaleString("en-US")} คน</h2>
                </div> */}
          <div className="bg-white border-[1px] border-solid mx-auto rounded-xl shadow-xl w-full md:w-[320px] p-1 m-2">
            <h2 className='text-md md:text-sm lg:text-md m-0 text-center'>เด็ก/เยาวชนที่ไม่มีข้อมูล</h2>
            <h2 className='text-md md:text-sm lg:text-md m-0 text-center'>ในระบบการศึกษาโครงการ ABE</h2>
            <h2 className='text-md lg:text-2xl text-center text-[#048967] m-0 font-bold'>{target_oosc.toLocaleString("en-US")} คน</h2>
          </div>
          <div className="bg-white border-[1px] border-solid mx-auto rounded-xl shadow-xl w-full md:w-[320px] p-1 m-2">
            <h2 className='text-md md:text-sm lg:text-md m-0 text-center'>เด็ก/เยาวชนที่ไม่มีข้อมูล</h2>
            <h2 className='text-md md:text-sm lg:text-md m-0 text-center'>ในระบบการศึกษาที่ได้รับการสำรวจ</h2>
            <h2 className='text-md lg:text-2xl text-center text-[#048967] m-0 font-bold'>{sum_oosc.toLocaleString("en-US")} คน</h2>
          </div>
        </div>
      </div>
      <Box className='text-center content-center' sx={{ borderBottom: 1, borderColor: 'divider' }}>
        {/* <div className='pt-5 px-5 text-cennter content-center bg-white rounded-lg mt-4 m-2'> */}
        <div className="w-full bg-white border-[1px] border-solid mx-auto rounded-xl shadow-xl p-2 my-2">
          <div className='flex flex-row'>
            <div className='w-1/5'>
              {
                isShow &&
                <button class="inline-flex items-center bg-red-500 border-0 py-1 m-2 px-3 focus:outline-none hover:bg-red-700 rounded text-base mt-4 md:mt-0 text-white"
                  onClick={() => {
                    if (props.filter.view === 'province') {
                      props.onUpdateFilter({
                        ...props.filter,
                        ...{
                          view: 'country',
                          region: "ทั้งหมด",
                          province_name: "ทั้งหมด",
                          city_name: null,
                          tumbon_name: null,
                        }
                      })
                    } else if (props.filter.view === 'city') {
                      props.onUpdateFilter({
                        ...props.filter,
                        ...{
                          view: 'province',
                          region: "ทั้งหมด",
                          city_name: null,
                          tumbon_name: null,
                        }
                      })
                    }
                  }}>
                  ย้อนกลับ
                </button>
              }

            </div>
            <div className='w-3/5'>
              <p className='text-center text-[#038967] font-bold text-xl lg:text-2xl m-1'>เด็กที่ไม่มีข้อมูลในระบบการศึกษา แบ่งตามจังหวัด</p>
            </div>
            <div className='w-1/5'>
            </div>
          </div>

          <Table
            size="small"
            bordered
            scroll={{ x: 800, y: 500 }}
            style={{ width: '100%' }}
            dataSource={props.data.filter(x => {
              return LIST_PROVICNE.includes(x.province)
            }).map(x => ({
              ...x,
              percent_table: ((x.sum_ecd / x.total_ecd) * 100).toFixed(2)
            }))

            }
            columns={[
              {
                title: titleTable,
                defaultSortOrder: 'ascend',
                dataIndex: key_map,
                key: key_map,
                width: 120,
                fixed: 'left',
                align: 'center',
                render(text, record) {
                  return {
                    props: {
                      style: { textAlign: 'left' }
                    },
                    children: <div onClick={() => {

                      if (props.filter.view === 'country') {
                        props.onUpdateFilter(
                          {
                            ...props.filter,
                            ...{
                              view: 'province',
                              region: "ทั้งหมด",
                              province_name: text,
                              city_name: null,
                              tumbon_name: null,
                            }
                          })
                      } else if (props.filter.view === 'province') {
                        props.onUpdateFilter(
                          {
                            ...props.filter,
                            ...{
                              view: 'city',
                              region: "ทั้งหมด",
                              province_name: props.filter.province_name,
                              city_name: text,
                              tumbon_name: null,
                            }
                          })
                      }
                    }}>
                      {props.filter.view !== 'city' ? <a > {text}</a> : <p className='m-0' > {text}</p>}
                    </div>
                  };
                },
                sorter: (a, b) => {
                  if (a[key_map] < b[key_map]) {
                    return -1;
                  }
                  if (a[key_map] > b[key_map]) {
                    return 1;
                  }
                  // a must be equal to b
                  return 0;
                },
              },

              // {

              //   title: 'เด็กที่ไม่มีข้อมูลในระบบการศึกษาทั้งหมด',
              //   dataIndex: 'total_oosc',
              //   key: 'total_oosc',
              //   align: 'center',
              //   render(text, record) {
              //     return {
              //       props: {
              //         style: { textAlign: "right" }
              //       },
              //       children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
              //     };
              //   },
              //   width: 200,
              //   sorter: (a, b) => a.total_oosc - b.total_oosc,
              // },
              {
                title: 'เด็กที่ไม่มีข้อมูลในระบบการศึกษาโครงการ ABE',
                dataIndex: 'total_target_map_oosc',
                key: 'total_target_map_oosc',
                align: 'center',
                render(text, record) {
                  return {
                    props: {
                      style: { textAlign: "right" }
                    },
                    children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                  };
                },
                width: 200,
                sorter: (a, b) => a.total_target_map_oosc - b.total_target_map_oosc,
              },
              {
                title: 'เด็กที่ไม่มีข้อมูลในระบบการศึกษาโครงการ ABE ที่ได้รับการสำรวจ',
                children: [
                  {
                    title: 'อายุ 3 -5 ปี',
                    dataIndex: 'oosc3_5',
                    key: 'oosc3_5',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      };
                    },
                    width: 110,
                    sorter: (a, b) => a.oosc3_5 - b.oosc3_5,
                  },
                  {
                    title: 'อายุ 6 - 11 ปี',
                    dataIndex: 'oosc6_11',
                    key: 'oosc6_11',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      };
                    },
                    width: 110,
                    sorter: (a, b) => a.oosc6_11 - b.oosc6_11,
                  },
                  {
                    title: 'อายุ 12 - 14 ปี',
                    dataIndex: 'oosc12_14',
                    key: 'oosc12_14',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      };
                    },
                    width: 110,
                    sorter: (a, b) => a.oosc12_14 - b.oosc12_14,
                  },
                  {
                    title: 'อายุ 15 - 17 ปี',
                    dataIndex: 'oosc15_17',
                    key: 'oosc15_17',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      };
                    },
                    width: 110,
                    sorter: (a, b) => a.oosc15_17 - b.oosc15_17,
                  },
                  {
                    title: 'รวม',
                    dataIndex: 'province20_map_oosc',
                    key: 'province20_map_oosc',
                    align: 'center',
                    render(text, record) {
                      return {
                        props: {
                          style: { textAlign: "right" }
                        },
                        children: <div>{text.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</div>
                      };
                    },
                    width: 80,
                    sorter: (a, b) => a.province20_map_oosc - b.province20_map_oosc,
                  },
                ]
              },

            ]}
          >
          </Table>
        </div>
      </Box>
    </div >
  )
}

export default Overview
