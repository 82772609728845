import * as React from 'react';
import { Col, Button, Checkbox } from 'antd';

export default function CheckbooxButton({ percent = 0, title = "", checked = false, onClick }) {
    let _color = percent > .5 ? '#2ED97A' : '#EE4141'
    return (
        <button
            onClick={onClick}
            style={{
                padding: '5px 15px 5px 15px',
                margin: '4px',
                borderRadius: '8px',
                background: checked ? '#22c55e' : '#efefef',
                fontSize: '14px',
            }}>
            {title}
            {/* <Checkbox onChange={onChange} checked={checked} style={{ color: '#fff' }}> {title}</Checkbox> */}
        </button>)
}
